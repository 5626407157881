import { DEFAULT_LAYOUT } from "../base";

const class_a = {
  path: "/",
  component: DEFAULT_LAYOUT,
  redirect: {
    name: "dashboard",
  },
  children: [
    {
      path: "/dashboard",
      name: "dashboard",
      component: () => import("@/views/dashboard"),
      meta: {
        title: "仪表盘",
      },
    },
    {
      path: "/message",
      name: "message",
      component: () => import("@/views/message"),
      meta: {
        title: "消息管理",
      },
    },
    {
      path: "/user",
      name: "user",
      component: () => import("@/views/user"),
      meta: {
        title: "用户管理",
      },
    },
  ],
};

export default class_a;

import { defineStore } from "pinia";

export const useUserStore = defineStore("user", {
    state: () => ({
      id: null,
    }),
  
    getters: {
      userInfo(state) {
        return {
          ...state,
        };
      },
    },
  
    actions: {
      // 设置用户信息
      setInfo(partial) {
        this.$patch(partial);
      },
    },
  });
  
  export default useUserStore;
import { createPinia } from "pinia";

import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import useUserStore from "./modules/user";
import useThemeStore from "./modules/theme";
import usePromptStore from "./modules/prompt";

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

export { useUserStore, useThemeStore, usePromptStore };
export default pinia;

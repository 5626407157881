/**
 * 路由守卫
 */
import NProgress from "nprogress";
import { setRouteEmitter } from "@/utils/route-listener";
import setupUserLoginInfoGuard from "./userLoginInfo";

function setupPageGuard(router) {
  router.beforeEach(async (to) => {
    NProgress.start();
    // emit route change
    setRouteEmitter(to);
    NProgress.done();
  });
}

export default function createRouteGuard(router) {
  setupPageGuard(router);
  setupUserLoginInfoGuard(router);
}

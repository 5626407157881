import { isLogin } from "@/utils/auth";

export default function setupUserLoginInfoGuard(router) {
  router.beforeEach(async (to, from, next) => {
    console.log(isLogin())
    if (isLogin()) {
      next();
    } else {
      if (to.name === "login") {
        next();
        return;
      }
      next({
        name: "login",
        query: {
          redirect: to.name,
          ...to.query,
        },
      });
    }
  });
}

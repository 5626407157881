import { DEFAULT_LAYOUT } from "../base";

const model = {
  path: "/",
  component: DEFAULT_LAYOUT,
  redirect: {
    name: "dashboard",
  },
  children: [
    {
      path: "/model/base",
      name: "baseModel",
      component: () => import("@/views/model/base.vue"),
      meta: {
        title: "大模型标签",
      },
    },
    {
      path: "/model/checkpoint",
      name: "checkpointModel",
      component: () => import("@/views/model/checkpoint.vue"),
      meta: {
        title: "checkpoint模型",
      },
    },
    {
      path: "/model/lora",
      name: "loraModel",
      component: () => import("@/views/model/lora.vue"),
      meta: {
        title: "lora模型",
      },
    },
  ],
};

export default model;

import { DEFAULT_LAYOUT } from "../base";

const category = {
  path: "/",
  component: DEFAULT_LAYOUT,
  redirect: {
    name: "dashboard",
  },
  children: [
    {
      path: "/category/explore",
      name: "categoryExplore",
      component: () => import("@/views/category/explore.vue"),
      meta: {
        title: "发现标签",
      },
    },
    {
      path: "/category/style",
      name: "categoryStyle",
      component: () => import("@/views/category/style.vue"),
      meta: {
        title: "图片风格",
      },
    },
    {
      path: "/category/proportion",
      name: "categoryProportion",
      component: () => import("@/views/category/proportion.vue"),
      meta: {
        title: "图片比例",
      },
    },
    {
      path: "/category/size",
      name: "categorySize",
      component: () => import("@/views/category/size.vue"),
      meta: {
        title: "图片尺寸",
      },
    },
  ],
};

export default category;

/**
 * 路由模块
 */
const modules = require.context("./modules", true, /\.js$/);
function formatModules(_modules, result) {
  _modules.keys().forEach((fileName) => {
    const routerConfig = _modules(fileName).default;
    result.push(routerConfig);
  });
  return result;
}

export const appRoutes = formatModules(modules, []);

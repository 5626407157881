/**
 * 身份标识本地处理。
 */

const isLogin = () => {
  return !!localStorage.getItem("TY-token");
};

const getToken = (TOKEN_KEY) => {
  return localStorage.getItem(TOKEN_KEY);
};

const setToken = (TOKEN_KEY, token) => {
  localStorage.setItem(TOKEN_KEY, token);
};

const clearToken = (TOKEN_KEY) => {
  localStorage.removeItem(TOKEN_KEY);
};

export { isLogin, getToken, setToken, clearToken };

import { DEFAULT_LAYOUT } from "../base";

const category = {
  path: "/",
  component: DEFAULT_LAYOUT,
  redirect: {
    name: "incantation",
  },
  children: [
    {
      path: "/incantation/one_level",
      name: "oneIncantation",
      component: () => import("@/views/incantation/one.vue"),
      meta: {
        title: "一级咒语",
      },
    },
    {
      path: "/incantation/two_level",
      name: "twoIncantation",
      component: () => import("@/views/incantation/two.vue"),
      meta: {
        title: "二级咒语",
      },
    },
    {
      path: "/incantation/three_level",
      name: "threeIncantation",
      component: () => import("@/views/incantation/three.vue"),
      meta: {
        title: "三级咒语",
      },
    },
  ],
};

export default category;

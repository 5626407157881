import { defineStore } from "pinia";

export const usePromptStore = defineStore("prompt", {
  state: () => ({
    promptStr: "",
  }),
  getters: {
    promptInfo: (state) => {
      return {
        ...state,
      };
    },
  },
  actions: {
    setPrompt(value) {
      console.log(value);
      this.$patch({
        promptStr: value,
      });
    },
  },
});

export default usePromptStore;
